import { json, type DataFunctionArgs, type MetaFunction } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { type RoleType } from 'types/enums.ts'
import { type DrawerItem } from '~/components/layout/drawer-navigator.tsx'
import { MainContainer } from '~/components/layout/main-container.tsx'
import { MainLayout } from '~/components/layout/main-layout.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { getUserRole } from '~/features/auth/auth.server.ts'
export const meta: MetaFunction = () => [{ title: 'Miistico' }]

export async function loader({ request }: DataFunctionArgs) {
  const userRole = await getUserRole(request)
  return json({ userRole })
}

export const professionalDrawerItems: DrawerItem[] = [
  {
    title: 'Inicio',
    icon: <Icon name="home" size="drawer" />,
    href: '/professional/',
    isExactPath: true,
  },
  {
    title: 'Mi perfil',
    icon: <Icon name="profile" size="drawer" />,
    href: '/professional/profile',
  },
  {
    title: 'Mis videos',
    icon: <Icon name="video" size="drawer" />,
    href: '/professional/videos',
  },
  {
    title: 'Mis horarios',
    icon: <Icon name="history" size="drawer" />,
    href: '/professional/schedules',
  },
  {
    title: 'Mis servicios',
    icon: <Icon name="service" size="drawer" />,
    href: '/professional/services',
  },
  {
    title: 'Mi calendario',
    icon: <Icon name="calendar" size="drawer" />,
    href: '/professional/calendar',
  },
]

export default function Index() {
  const { userRole } = useLoaderData<typeof loader>()
  return (
    <MainLayout userRole={userRole as RoleType}>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </MainLayout>
  )
}
