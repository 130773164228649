import { Input } from '~/components/ui/inputs/input.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'
import { Icon } from '../../components/ui/icons/icon.tsx'
import { ButtonTrigger } from '~/components/ui/inputs/button/Button.tsx'
import { useState } from 'react'

export function HomeSearchInput({
  search: initialSearch,
  handleSearchChange,
  autoFocus = false,
  autoSubmit = false,
  variant,
}: {
  search: string
  handleSearchChange: (value: string) => void
  autoFocus?: boolean
  autoSubmit?: boolean
  variant: 'professionals' | 'services'
}) {
  const [search, setSearch] = useState(initialSearch)
  return (
    <div className="flex  w-full items-center justify-end gap-1 sm:w-auto md:w-[500px]">
      <Label htmlFor="search" className="sr-only">
        Search
      </Label>
      <Input
        variant="outlined"
        type="search"
        name="search"
        id="search"
        defaultValue={search}
        placeholder={`Buscar ${
          variant === 'professionals' ? 'profesional' : 'servicio'
        }`}
        className="w-full rounded-full bg-white lg:max-w-[400px]"
        autoFocus={autoFocus}
        leftIcon={<Icon name="search" />}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleSearchChange(search)
          }
        }}
        onChange={e => {
          setSearch(e.target.value)
          if (!e.target.value) {
            handleSearchChange(e.target.value)
            return
          }
          if (autoSubmit) {
            handleSearchChange(e.target.value)
            return
          }
        }}
      />
      <ButtonTrigger
        className="flex w-[100px] items-center justify-center"
        size="sm"
        variant={'cancel'}
        onClick={() => handleSearchChange(search)}
      >
        Buscar
      </ButtonTrigger>
    </div>
  )
}
