import type SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Zoom } from 'swiper/modules';
import "swiper/swiper-bundle.css?__remix_sideEffect__";
import { useEffect, useState } from 'react';
import { cn } from '~/utils/misc.tsx';
import { type SwiperOptions } from 'swiper/types';

type Props = {
  slides: any[];
  initialIndex?: number; // Make initialIndex optional
  slidesPerView: number;
  paginationContainerClassName?: string;
  className?: string;
  withPagination?: boolean;
  spaceBetween?: number;
  loop?: boolean;
  breakpoints?: SwiperOptions['breakpoints'];
  swiper?: SwiperCore;
  setSwiper?: (swiper: SwiperCore) => void; // Add setSwiper prop
  activeIndex?: number;
  onActiveIndexChange?: (index: number) => void;
  zoom?: boolean;
};

export const Carousel = ({
  initialIndex = 0, // Set default value to 0
  loop,
  slides,
  spaceBetween,
  slidesPerView = 1,
  paginationContainerClassName,
  withPagination,
  className,
  breakpoints,
  swiper: externalSwiper,
  setSwiper, // Add setSwiper to destructured props
  activeIndex: controlledActiveIndex,
  onActiveIndexChange,
  zoom = false
}: Props) => {
  const [internalSwiper, setInternalSwiper] = useState<SwiperCore | undefined>(
    undefined
  );
  const [internalActiveIndex, setInternalActiveIndex] = useState(initialIndex);

  // Use controlled or internal state
  const activeIndex =
  controlledActiveIndex !== undefined ?
  controlledActiveIndex :
  internalActiveIndex;

  const swiper = externalSwiper || internalSwiper;

  useEffect(() => {
    if (externalSwiper) {
      setInternalActiveIndex(externalSwiper.realIndex);
    }
  }, [externalSwiper]);

  const handleActiveIndexChange = (index: number) => {
    setInternalActiveIndex(index);
    onActiveIndexChange?.(index);
  };

  const handlePaginationClick = (index: number) => {
    if (loop) {
      swiper?.slideToLoop(index);
    } else {
      swiper?.slideTo(index);
    }
    handleActiveIndexChange(index);
  };

  return (
    <Swiper
      zoom={zoom}
      initialSlide={initialIndex}
      spaceBetween={spaceBetween}
      className={className}
      modules={[A11y, Zoom]}
      breakpoints={breakpoints}
      slidesPerView={slidesPerView}
      onSwiper={(newSwiper) => {
        if (setSwiper) {
          setSwiper(newSwiper);
        } else {
          setInternalSwiper(newSwiper);
        }
      }}
      loop={loop}
      onSlideChange={(changedSwiper) => {
        handleActiveIndexChange(changedSwiper.realIndex);
      }}>

      {slides.map((slide, index) =>
      <SwiperSlide zoom={zoom} key={index}>
          {slide}
        </SwiperSlide>
      )}
      {/* Pagination */}
      {withPagination &&
      <div
        slot="container-end"
        className={cn(
          'mt-9 flex justify-center gap-2',
          paginationContainerClassName
        )}>

          {slides.map((_, index) =>
        <div
          key={index}
          className="flex h-[24px] cursor-pointer items-center"
          onClick={() => handlePaginationClick(index)}>

              <button
            key={index}
            className={`
                  h-[4px] w-9 min-w-9 rounded-[4px]

                  ${activeIndex === index ? 'bg-pink-600' : 'bg-purple-300'}
                `} />

            </div>
        )}
        </div>}

    </Swiper>);

};