import { useState, useRef, useEffect, Fragment } from 'react'
import { Spacer } from '~/components/ui/spacer.tsx'
import Spinner from '~/components/ui/spinner.tsx'

interface InfiniteScrollProps<T> {
  items: T[]
  renderItem: (item: T) => React.ReactNode
  batchSize?: number
}

export function InfiniteScroll<T>({
  items,
  renderItem,
  batchSize = 10,
}: InfiniteScrollProps<T>) {
  const [visibleItems, setVisibleItems] = useState<any>([
    ...items?.slice(0, batchSize),
  ])

  useEffect(() => {
    setVisibleItems(items?.slice(0, batchSize) || [])
  }, [items, batchSize])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadMoreRef = useRef<HTMLDivElement>(null)

  const loadMoreItems = () => {
    setIsLoading(true)

    const nextBatch = items?.slice(
      visibleItems.length,
      visibleItems.length + batchSize,
    )
    setVisibleItems([...visibleItems, ...nextBatch])
    setIsLoading(false)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadMoreItems()
          }
        })
      },
      { threshold: 0.1 },
    )

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [visibleItems])

  return (
    <>
      <div className="grid grid-cols-1 gap-8 xl:grid-cols-2">
        {visibleItems.map((item: T, index: number) => (
          <Fragment key={index}>{renderItem(item)}</Fragment>
        ))}
      </div>
      {visibleItems.length !== items.length && (
        <div ref={loadMoreRef} className="w-full" />
      )}
      <Spacer size="2xs" />
      {isLoading && visibleItems.length < items.length && (
        <div className="flex flex-col items-center justify-center space-y-3">
          <Spinner size={10} color="text-gray-200" />
        </div>
      )}
    </>
  )
}
