import { Icon } from '../../components/ui/icons/icon.tsx'
import { Checkbox } from '../../components/ui/inputs/checkbox.tsx'
import { ScrollArea } from '../../components/ui/scroll-area.tsx'
import { Spacer } from '../../components/ui/spacer.tsx'
import * as Slider from '@radix-ui/react-slider'
import * as Dialog from '@radix-ui/react-dialog'
import { Separator } from '../../components/ui/separator.tsx'
import { type HomeFilters } from './useHomeFilters.tsx'
import { SessionTypeEnum } from 'types/enums.ts'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ClientOnly } from 'remix-utils/client-only'

type Props = {
  categoriesSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  topicsSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  totalItems: number
  resultsNumber: number
  filters: HomeFilters
  resetFilters: () => void
  handlers: {
    handleHourChange: (value: [number, number]) => void
    handleCheckBoxChange: (
      filter: 'categories' | 'topics' | 'type',
      slug: string,
      isNewValue: boolean,
    ) => void
    handleTodayCheckChange: (value: boolean) => void
  }
}

type AccordionValue = 'service' | 'time' | 'topic' | 'format'

export default function Filter({
  categoriesSelectData,
  topicsSelectData,
  totalItems,
  resultsNumber,
  filters,
  resetFilters,
  handlers,
}: Props) {
  const [accordionValue, setAccordionValue] = useState<AccordionValue[]>([
    'service',
    'time',
    'topic',
    'format',
  ])
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  })

  useEffect(() => {
    if (!isMobile && accordionValue.length !== 4) {
      setAccordionValue(['service', 'time', 'topic', 'format'])
    }
  }, [isMobile])
  const { handleHourChange, handleCheckBoxChange, handleTodayCheckChange } =
    handlers

  const sessionTypeOptions = [
    { label: 'Escrito / Audio', slug: SessionTypeEnum.WRITTEN },
    { label: 'Videollamada', slug: SessionTypeEnum.VIDEO_CALL },
  ]

  const handleChangeAccordionValue = (accordionValue: AccordionValue) => {
    if (!isMobile) return
    setAccordionValue(prevValues => {
      if (prevValues.includes(accordionValue)) {
        // If value is in the array, remove it
        return prevValues.filter(item => item !== accordionValue)
      } else {
        // If value is not in the array, add it
        return [...prevValues, accordionValue]
      }
    })
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger className="hidden outline-none sm:flex" asChild>
        <button className="flex w-[150px] items-center justify-center space-x-2 rounded-full bg-white px-4 py-3 shadow-md">
          <p className={`text-sm font-bold`}>Filtros</p>
          <Icon name="chevron-down" className="text-black" size="drawer" />
        </button>
      </Dialog.Trigger>
      <Dialog.Trigger className="outline-none sm:hidden" asChild>
        <button>
          <Icon
            name="sliders-horizontal"
            className="text-secondary"
            size="lg"
          />
        </button>
      </Dialog.Trigger>

      <Dialog.Trigger className="outline-none sm:hidden" asChild>
        <div className="fixed left-1/2 top-[90%] z-30 flex -translate-x-[50%] cursor-pointer items-center space-x-4 rounded-full bg-secondary-700 px-4 py-2 shadow-floating-button">
          <p className="text-h6 text-white">Filtros</p>
        </div>
      </Dialog.Trigger>
      <ClientOnly>
        {() => (
          <Dialog.Portal container={document.getElementById('root')}>
            <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-0 top-0 z-30 max-h-screen w-screen overflow-hidden bg-white focus:outline-none sm:absolute sm:top-48 sm:h-[600px] sm:min-h-[320px] sm:w-full sm:rounded-[10px] sm:p-[25px] sm:shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] lg:top-40 lg:h-[400px] xl:top-[64px]">
              <div className="flex h-screen w-screen flex-col overflow-hidden p-4 sm:block sm:h-full sm:w-full sm:p-0">
                <div className="hidden w-full items-center justify-between sm:flex">
                  <p
                    className="cursor-pointer border-b border-dotted border-secondary-400 text-xs font-semibold text-secondary-400"
                    onClick={resetFilters}
                  >
                    Quitar filtros
                  </p>
                  <Dialog.Trigger asChild>
                    <div className="flex cursor-pointer items-center justify-center space-x-5">
                      <p className={`text-sm`}>Filtros</p>
                      <Icon
                        name="chevron-down"
                        className="rotate-180 text-black"
                        size="drawer"
                      />
                    </div>
                  </Dialog.Trigger>
                </div>

                <Separator className="hidden w-full sm:inline-flex" />

                <div className="flex w-full flex-col items-center space-y-1 sm:hidden">
                  <p className="p-1 text-h6">Filtros</p>
                  <Dialog.Close className="text-primary">
                    <Icon
                      name="chevron-down"
                      className="fixed left-3 top-4 rotate-90 text-secondary-400"
                      size="lg"
                    />
                  </Dialog.Close>
                  <Separator className="w-full" />
                  <div className="flex w-full items-center justify-between px-2 py-2">
                    <p className="text-xs">
                      {resultsNumber} of {totalItems}
                    </p>
                    <p
                      className="cursor-pointer border-b border-dotted border-black text-xs font-semibold text-secondary-400"
                      onClick={resetFilters}
                    >
                      Quitar filtros
                    </p>
                  </div>
                  <Separator className="w-full shadow-xl" />
                </div>

                <Spacer size="4xs" />
                <div className="flex w-full flex-col items-start gap-4 space-y-1 overflow-y-auto px-4 pb-10 sm:flex-row">
                  <div className="flex w-full flex-col flex-wrap px-4 py-2 sm:relative sm:px-0 sm:py-0">
                    <div
                      className="flex w-full cursor-pointer select-none items-center justify-between sm:mt-0 sm:cursor-auto"
                      onClick={() => handleChangeAccordionValue('service')}
                    >
                      <p className="text-h6 !font-semibold sm:text-h5">
                        Servicio
                      </p>
                      <Icon
                        name="chevron-down"
                        className="rotate-180 text-black sm:hidden"
                        size="drawer"
                      />
                    </div>
                    {accordionValue.includes('service') && (
                      <>
                        <Spacer size="3xs" />
                        <ScrollArea className="sm:h-[200px]">
                          <div className="grid grid-cols-1 gap-y-5 2xl:grid-cols-2">
                            {categoriesSelectData.map(({ name, slug }) => {
                              const id = 'category-' + name
                              return (
                                <div
                                  className="flex items-center space-x-3"
                                  key={name}
                                >
                                  <Checkbox
                                    id={id}
                                    checked={filters.categories.includes(
                                      String(slug),
                                    )}
                                    value={String(slug)}
                                    onCheckedChange={(value: boolean) =>
                                      handleCheckBoxChange(
                                        'categories',
                                        String(slug),
                                        value,
                                      )
                                    }
                                    className="h-[20px] w-[20px] rounded-full"
                                    color="secondary"
                                  />
                                  <label
                                    htmlFor={id}
                                    className="m-0 text-xs capitalize xl:text-sm"
                                  >
                                    {name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </ScrollArea>
                      </>
                    )}
                  </div>
                  <Separator
                    className="hidden h-[260px] sm:block"
                    orientation="vertical"
                  />
                  <Separator className="w-full sm:hidden" />
                  <div className="flex w-full flex-col flex-wrap px-4 py-2 sm:relative sm:px-0 sm:py-0">
                    <div
                      className="flex w-full cursor-pointer select-none items-center justify-between sm:mt-0 sm:cursor-auto"
                      onClick={() => handleChangeAccordionValue('time')}
                    >
                      <p className="text-h6 !font-semibold sm:text-h5">Hora</p>
                      <Icon
                        name="chevron-down"
                        className="rotate-180 text-black sm:hidden"
                        size="drawer"
                      />
                    </div>
                    {accordionValue.includes('time') && (
                      <>
                        <Spacer size="3xs" />
                        <div className="flex w-full flex-col">
                          <Slider.Root
                            className="relative flex h-4 w-full max-w-[200px] touch-none select-none items-center px-3 sm:h-5"
                            defaultValue={filters.hour}
                            max={24}
                            step={1}
                            minStepsBetweenThumbs={1}
                            onValueCommit={handleHourChange}
                          >
                            <Slider.Track className="relative h-[2px] grow rounded-full bg-gray-200 sm:h-[3px]">
                              <Slider.Range className="absolute h-full rounded-full bg-secondary" />
                            </Slider.Track>
                            <Slider.Thumb
                              className="block h-3 w-3 cursor-pointer rounded-full bg-secondary"
                              aria-label="Volume"
                            />
                            <Slider.Thumb
                              className="block h-3 w-3 cursor-pointer rounded-full bg-secondary"
                              aria-label="Volume"
                            />
                            <div className="absolute left-0 top-[115%] flex w-full items-center justify-between px-4 sm:px-0">
                              <span className="text-nowrap text-xs text-secondary">
                                {filters.hour[0]} hr
                              </span>
                              <span className="text-nowrap text-xs text-secondary">
                                {filters.hour[1]}hr
                              </span>
                            </div>
                          </Slider.Root>

                          <Spacer size="xs" />
                          <Separator className="hidden w-full max-w-[200px] sm:inline-flex" />
                          <div className="hidden sm:block">
                            <Spacer size="2xs" />
                          </div>
                          <div className="flex items-center space-x-3">
                            <Checkbox
                              id="today"
                              checked={filters.today}
                              onCheckedChange={handleTodayCheckChange}
                              className="h-[20px] w-[20px] rounded-full"
                              color="secondary"
                            />
                            <label
                              htmlFor="today"
                              className="m-0 text-xs capitalize xl:text-sm"
                            >
                              Disponibles hoy
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Separator
                    className="hidden h-[260px] sm:block"
                    orientation="vertical"
                  />
                  <Separator className="w-full sm:hidden" />
                  <div className="flex w-full flex-col flex-wrap px-4 py-1 pb-2 sm:relative sm:px-0 sm:py-0">
                    <div
                      className="flex w-full cursor-pointer select-none items-center justify-between sm:cursor-auto"
                      onClick={() => handleChangeAccordionValue('topic')}
                    >
                      <p className="text-h6 !font-semibold sm:text-h5">Temas</p>
                      <Icon
                        name="chevron-down"
                        className="rotate-180 text-black sm:hidden"
                        size="drawer"
                      />
                    </div>
                    {accordionValue.includes('topic') && (
                      <>
                        <Spacer size="3xs" />
                        <ScrollArea className="sm:h-[200px]">
                          <div className="grid grid-cols-1 gap-y-5">
                            {topicsSelectData.map(({ name, slug }) => {
                              const id = 'topic-' + name
                              return (
                                <div
                                  className="flex items-center space-x-3"
                                  key={name}
                                >
                                  <Checkbox
                                    id={id}
                                    checked={filters.topics.includes(
                                      String(slug),
                                    )}
                                    value={String(slug)}
                                    onCheckedChange={(value: boolean) =>
                                      handleCheckBoxChange(
                                        'topics',
                                        String(slug),
                                        value,
                                      )
                                    }
                                    className="h-[20px] w-[20px] rounded-full"
                                    color="secondary"
                                  />
                                  <label
                                    htmlFor={id}
                                    className="m-0 text-xs capitalize xl:text-sm"
                                  >
                                    {name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </ScrollArea>
                      </>
                    )}
                  </div>
                  <Separator
                    className="hidden h-[260px] sm:block"
                    orientation="vertical"
                  />
                  <Separator className="w-full sm:hidden" />
                  <div className="flex w-full flex-col flex-wrap px-4 py-1 pb-2 sm:px-0 sm:py-0">
                    <div
                      className="flex w-full cursor-pointer select-none items-center justify-between sm:cursor-auto"
                      onClick={() => handleChangeAccordionValue('format')}
                    >
                      <p className="text-h6 !font-semibold sm:text-h5">
                        Formato
                      </p>
                      <Icon
                        name="chevron-down"
                        className="rotate-180 text-black sm:hidden"
                        size="drawer"
                      />
                    </div>
                    {accordionValue.includes('format') && (
                      <>
                        <Spacer size="3xs" />
                        <ScrollArea className="sm:h-[200px]">
                          <div className="grid grid-cols-1 gap-y-5">
                            {sessionTypeOptions.map(({ label, slug }) => {
                              const id = 'type-' + label
                              return (
                                <div
                                  className="flex items-center space-x-3"
                                  key={label}
                                >
                                  <Checkbox
                                    id={id}
                                    checked={filters.type.includes(
                                      String(slug),
                                    )}
                                    value={String(slug)}
                                    onCheckedChange={(value: boolean) =>
                                      handleCheckBoxChange(
                                        'type',
                                        String(slug),
                                        value,
                                      )
                                    }
                                    className="h-[20px] w-[20px] rounded-full"
                                    color="secondary"
                                  />
                                  <label
                                    htmlFor={id}
                                    className="m-0 text-xs capitalize xl:text-sm"
                                  >
                                    {label}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </ScrollArea>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Dialog.Close className="fixed bottom-2 left-1/2 w-[90%] -translate-x-[50%] cursor-pointer rounded-full bg-secondary-700 px-4 py-2 sm:absolute sm:bottom-6 sm:right-[50%] sm:hidden sm:w-[350px] lg:bottom-6 lg:right-5 lg:translate-x-0">
                <p className="text-h6 text-white">
                  Ver {resultsNumber} resultados
                </p>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </ClientOnly>
      {/* Link the dialog portal to the main. The main element is the dialog portal parent */}
    </Dialog.Root>
  )
}
