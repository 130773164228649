import { useSearchParams } from '@remix-run/react'
import { useState, useEffect } from 'react'
import { HomeFilterEnum } from 'types/enums.ts'

export type HomeFilters = {
  [HomeFilterEnum.CATEGORIES]: string[]
  [HomeFilterEnum.TOPICS]: string[]
  [HomeFilterEnum.TOPICS_CAROUSEL]: string[]
  [HomeFilterEnum.TYPE]: string[]
  [HomeFilterEnum.HOUR]: number[]
  [HomeFilterEnum.TODAY]: boolean
  [HomeFilterEnum.SEARCH]: string
}

export default function useFilters() {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const [filters, setFilters] = useState<HomeFilters>({
    categories: searchParams.get(HomeFilterEnum.CATEGORIES)?.split(',') ?? [],
    topics: searchParams.get(HomeFilterEnum.TOPICS)?.split(',') ?? [],
    topicsCarousel:
      searchParams.get(HomeFilterEnum.TOPICS_CAROUSEL)?.split(',') ?? [],
    type: searchParams.get(HomeFilterEnum.TOPICS)?.split(',') ?? [],
    hour: searchParams.get(HomeFilterEnum.HOUR)?.split(',').map(Number) ?? [
      0, 24,
    ],
    today: Boolean(searchParams.get(HomeFilterEnum.TODAY)) ?? false,
    search: searchParams.get(HomeFilterEnum.SEARCH) ?? '',
  })

  const resetFilters = () => {
    setFilters({
      categories: [],
      topics: [],
      topicsCarousel: [],
      type: [],
      hour: [0, 24],
      today: false,
      search: '',
    })
  }

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
      return
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (
        // in case of today, remove it if is false
        !value ||
        (Array.isArray(value) &&
          (!value.length ||
            // in case of hours, remove it if is all day
            (value.length === 2 && value[0] === 0 && value[1] === 24)))
      ) {
        searchParams.delete(key)
        return
      }
      searchParams.set(key, value.toString())
    })
    setSearchParams(searchParams, {
      preventScrollReset: true,
      replace: true,
    })
  }, [filters])

  const handleCheckBoxChange = (
    filter:
      | typeof HomeFilterEnum.CATEGORIES
      | typeof HomeFilterEnum.TOPICS
      | typeof HomeFilterEnum.TYPE,
    slug: string,
    isNewValue: boolean,
  ) => {
    if (isNewValue) {
      setFilters({
        ...filters,
        [filter]: [...filters[filter], slug],
      })
      return
    }
    setFilters({
      ...filters,
      [filter]: filters[filter].filter(currentSlugs => currentSlugs !== slug),
    })
  }

  const handleTodayCheckChange = (value: boolean) => {
    setFilters({
      ...filters,
      today: value,
    })
  }

  const handleHourChange = (value: [number, number]) => {
    setFilters({
      ...filters,
      hour: value,
    })
  }

  const handleSearchChange = (value: string) => {
    setFilters({
      ...filters,
      search: value,
    })
  }

  return {
    filters,
    handleHourChange,
    handleCheckBoxChange,
    handleTodayCheckChange,
    handleSearchChange,
    resetFilters,
    searchParams,
  }
}
