import { Form, Link, useMatches } from '@remix-run/react'
import React from 'react'
import { Icon } from '../ui/icons/icon.tsx'
import { useOptionalUser } from '~/features/auth/user.ts'

export interface DrawerItem {
  title: string
  icon: React.ReactNode // Change the type to React.ReactNode
  href: string
  isExactPath?: boolean
}

interface LeftDrawerNavigatorProps {
  items: DrawerItem[]
  isOpen: boolean | undefined
  onClose: () => void
  isMobile: boolean
}

const DrawerNavigator = ({
  items,
  isOpen: isOpenProp,
  onClose,
  isMobile,
}: LeftDrawerNavigatorProps) => {
  const matches = useMatches()
  const pathName = matches[matches.length - 1].pathname
  const user = useOptionalUser()

  const isOpen = isOpenProp ?? !isMobile

  const isOpenStyles = () => {
    return isOpen
      ? '!w-[340px] !max-w-full sm:!w-[230px]'
      : '!w-[0px] sm:!w-[80px] invisible sm:visible'
  }

  return (
    <div className={isMobile ? 'sm:hidden' : 'hidden sm:block'}>
      <aside
        className={`fixed top-0 flex h-screen w-[0] shadow-nav transition-all duration-100 sm:sticky
      sm:w-[250px] sm:shadow-none ${isOpenStyles()} ${isMobile && 'z-[100]'}`}
      >
        <div className="flex w-full flex-col justify-between bg-card">
          <div className="flex justify-between">
            {isOpen ? (
              <a href="https://miistico.com/" className="w-full px-4 pt-1">
                <img
                  className="h-[64px] max-w-none sm:w-full sm:object-contain"
                  src="/img/miistico-gris.png"
                  alt="miistico logo"
                />
              </a>
            ) : (
              <a
                href="https://miistico.com/"
                className="flex h-[64px] w-full items-center justify-center px-4"
              >
                <img
                  className="h-[32px]"
                  src="/img/FAVICON MIISTICO.png"
                  alt="miistico logo"
                />
              </a>
            )}
            <Icon
              name="close"
              size="drawer"
              className="mr-2 cursor-pointer text-foreground sm:hidden"
              onClick={onClose}
            />
          </div>
          <nav className="flex flex-1 flex-col p-2 pt-6 sm:shadow-nav">
            <div className="flex flex-1 flex-col space-y-2">
              {/* {isOpen && ( */}
              {/*   <div className='flex justify-center'> */}
              {/*     <div> */}
              {/*       <img */}
              {/*         className="rounded-full w-[90px] h-[90px] object-cover" */}
              {/*         alt={user?.firstName} */}
              {/*         // src={getUserImgSrc(user?.imageId)} */}
              {/*         src={getUserImgSrc()} */}
              {/*       /> */}
              {/*       <h4 className='my-2 font-extrabold text-[24px] text-center text-secondary'>Invitado</h4> */}
              {/*     </div> */}
              {/*   </div> */}
              {/* )} */}
              {items.map((item, index) => (
                <DrawerLink
                  item={item}
                  pathName={pathName}
                  key={index}
                  onNavigate={() => (isMobile ? onClose() : null)}
                />
              ))}
            </div>
            {user && (
              <div className="flex flex-1 flex-col justify-end space-y-2">
                <Form
                  action="/logout"
                  method="POST"
                  className={`rounded-md text-foreground-not-selected hover:bg-background hover:text-black`}
                >
                  <button
                    type="submit"
                    className={`flex w-full cursor-pointer items-center justify-start space-x-5 overflow-hidden px-5 py-3 [&>svg]:text-secondary`}
                  >
                    <Icon name="logout" size="drawer" />
                    <div className={`ml-1 whitespace-nowrap `}>
                      Cerrar sesión
                    </div>
                  </button>
                </Form>
              </div>
            )}
          </nav>
        </div>
      </aside>
      {isOpen && (
        <div
          className={`fixed inset-0 z-50 h-full w-full bg-overlay sm:hidden`}
          onClick={onClose}
        />
      )}
    </div>
  )
}

const isActiveLink = (pathName: string, item: DrawerItem) => {
  if (item.isExactPath) {
    return pathName === item.href
  }
  return pathName.includes(item.href)
}
const DrawerLink = ({
  pathName,
  item,
  onNavigate,
}: {
  pathName: string
  item: DrawerItem
  onNavigate: () => void
}) => (
  <Link
    className={`rounded-md text-foreground-not-selected ${
      isActiveLink(pathName, item)
        ? 'bg-secondary-200 font-bold text-secondary-700 hover:text-secondary-700'
        : 'hover:bg-background hover:text-black'
    }`}
    to={item.href}
    key={item.title}
    onClick={onNavigate}
  >
    <div
      className={`flex cursor-pointer items-center justify-start space-x-5 px-5 py-3`}
    >
      {item.icon}
      <span className={`overflow-hidden whitespace-nowrap`}>{item.title}</span>
    </div>
  </Link>
)

export default DrawerNavigator
