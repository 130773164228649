import { Spacer } from '../../components/ui/spacer.tsx'
import { HomeSearchInput } from './homeSearchInput.tsx'
import { useMatches, useNavigate } from '@remix-run/react'
import { type ReactNode, useEffect } from 'react'
import { sendEventCategory } from '../analytics/connectif-events.client.ts'
import { useConnectifContext } from '../analytics/ConnectifProvider.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { Separator } from '~/components/ui/separator.tsx'
import Filter from '~/features/home/filter.tsx'
import useFilters from './useHomeFilters.tsx'
import { type Topic } from '@prisma/client'
import { Carousel } from '~/components/ui/carousel.tsx'
import { cn, getTopicImgSrc } from '~/utils/misc.tsx'
interface Props {
  variant: 'professionals' | 'services'
  resultsNumber: number
  totalItems: number
  categoriesSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  topicsCarouselData: Topic[]
  topicsSelectData: {
    name: string
    slug: string | null
    id: number
  }[]
  children: ReactNode
}

export function ProfessionalList({
  variant,
  resultsNumber,
  categoriesSelectData,
  topicsSelectData,
  topicsCarouselData,
  children,
  totalItems,
}: Props) {
  const navigate = useNavigate()
  const matches = useMatches()
  const { entityInfo } = useConnectifContext()
  const carouselFilters = true
  const carouselShowNames = true
  const rootSlug = matches[matches.length - 1].params.rootSlug
  const mobileCarouselSlides =
    topicsCarouselData.length <= 3 ? topicsCarouselData.length : 3
  const {
    filters,
    searchParams,
    handleSearchChange,
    resetFilters,
    ...otherHandlers
  } = useFilters()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (filters.categories.length) {
        filters.categories.forEach(category => {
          sendEventCategory(category, entityInfo)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootSlug])

  return (
    <div className="relative" id="root">
      <div className="items-center justify-between gap-4 xl:flex">
        {/* Add switch between professionals */}
        <div className="flex items-center justify-between">
          <div className="flex w-[300px] items-center justify-center space-x-3 rounded-full bg-white px-4 py-3 shadow-md">
            <div
              onClick={() => navigate(`/servicios?${searchParams}`)}
              className="flex cursor-pointer items-center space-x-2"
            >
              <Icon
                name="star-filled"
                className="text-secondary"
                size="drawer"
              />
              <p
                className={cn(
                  `text-sm`,
                  variant === 'services' ? 'font-bold' : '',
                )}
              >
                Servicios
              </p>
            </div>
            <Separator className="h-[30px]" orientation="vertical" />
            <div
              onClick={() => navigate(`/profesionales?${searchParams}`)}
              className="flex cursor-pointer items-center space-x-2"
            >
              <p
                className={cn(
                  `text-sm`,
                  variant === 'professionals' ? 'font-bold' : '',
                )}
              >
                Profesionales
              </p>
              <Icon name="profile" className="text-secondary" size="drawer" />
            </div>
          </div>
          <div className="block sm:hidden">
            <Filter
              categoriesSelectData={categoriesSelectData}
              topicsSelectData={topicsSelectData}
              resultsNumber={resultsNumber}
              totalItems={totalItems}
              filters={filters}
              resetFilters={resetFilters}
              handlers={otherHandlers}
            />
          </div>
        </div>
        <div className="mt-4 flex w-full flex-col items-center gap-2 sm:flex-row lg:gap-5 xl:mt-0 xl:justify-end">
          <HomeSearchInput
            variant={variant}
            search={filters.search}
            handleSearchChange={handleSearchChange}
          />
          <div className="hidden sm:block">
            <Filter
              categoriesSelectData={categoriesSelectData}
              topicsSelectData={topicsSelectData}
              resultsNumber={resultsNumber}
              totalItems={totalItems}
              filters={filters}
              resetFilters={resetFilters}
              handlers={otherHandlers}
            />
          </div>
        </div>
      </div>

      <div
        className={cn(
          `mt-4 w-full  justify-center p-3 `,
          topicsCarouselData.length === 0 ? 'hidden' : '',
        )}
      >
        <Carousel
          slidesPerView={mobileCarouselSlides}
          breakpoints={{
            680: {
              slidesPerView: Math.min(5.3, topicsCarouselData.length),
            },
            1280: {
              slidesPerView: Math.min(7.3, topicsCarouselData.length),
            },
          }}
          spaceBetween={0}
          slides={topicsCarouselData.map((topic: any) => {
            return (
              <div className="flex h-full flex-col items-center" key={topic.id}>
                <div
                  key={topic.id}
                  className={cn(
                    'mr-4 flex flex-col items-center',
                    filters.topics.includes(String(topic.slug)) &&
                      'rounded-lg  border-[2px] border-input bg-secondary-200 p-1 text-secondary',
                  )}
                  onClick={() => {
                    if (carouselFilters) {
                      otherHandlers.handleCheckBoxChange(
                        'topics',
                        String(topic.slug),
                        !filters.topics.includes(String(topic.slug)),
                      )
                    }
                  }}
                >
                  <div
                    key={topic.id}
                    title={topic.name || 'N/A'}
                    className={cn(
                      `mb-1 flex h-7 w-7 items-center justify-center`,
                    )}
                  >
                    {(topic as any).topicIcon ? (
                      <img
                        src={getTopicImgSrc((topic as any).topicIcon.id)}
                        alt={topic.name}
                        className="h-full w-full max-w-[100px] text-secondary"
                      />
                    ) : (
                      <Icon
                        name="category"
                        className="h-auto w-full max-w-[100px] text-secondary"
                      />
                    )}
                  </div>
                  {carouselShowNames && (
                    <p
                      className={cn(
                        'font-open-sans text-center text-sm',
                        filters.topics.includes(String(topic.slug))
                          ? 'text-secondary'
                          : 'text-[#565656]',
                      )}
                    >
                      {topic.name}
                    </p>
                  )}
                </div>
              </div>
            )
          })}
        />
      </div>

      <Spacer size="4xs" />
      {children}
    </div>
  )
}
