import { Icon } from '../ui/icons/icon.tsx'

type Props = {
  onHamburgerMenu: () => void
}

export const AppBar = ({ onHamburgerMenu }: Props) => {
  return (
    <header className="sticky top-0 flex w-full justify-between bg-white sm:top-3  sm:w-fit sm:justify-start">
      <div
        className="order-2 cursor-pointer p-3 sm:order-1 sm:p-2"
        onClick={onHamburgerMenu}
      >
        <Icon className="text-body-md " name="hamburger-menu" />
      </div>
      <a
        href="https://miistico.com/"
        className="order-1 px-2 sm:order-2 sm:hidden sm:px-4"
      >
        <img
          className="h-[56px]"
          src="/img/miistico-gris.png"
          alt="miistico logo"
        />
      </a>
    </header>
  )
}
