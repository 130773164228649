import { Link } from '@remix-run/react'

export function Footer() {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-3 bg-white px-4 py-4 text-lg md:flex-row md:gap-12`}
    >
      <Link
        to={'/legal'}
        className="text-center text-foreground hover:text-foreground-muted"
      >
        Aviso legal
      </Link>
      <Link
        to={'/terms'}
        className="text-wrap text-center text-foreground hover:text-foreground-muted"
      >
        Términos y condiciones de uso
      </Link>
      <Link
        to={'/privacy'}
        className="text-center text-foreground hover:text-foreground-muted"
      >
        Política de privacidad
      </Link>
    </div>
  )
}
