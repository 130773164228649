import DrawerNavigator from './drawer-navigator.tsx'
import { AppBar } from './app-bar.tsx'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { type RoleType } from '~/models/user.ts'
import { userDrawerItems } from '~/routes/_user+/user.tsx'
import { professionalDrawerItems } from '~/routes/_professional+/professional.tsx'
import { adminDrawerItems } from '~/routes/_admin+/admin.tsx'
import { guestDrawerItems } from '~/routes/_guest+/index.tsx'
import { Footer } from './footer.tsx'
import { Spacer } from '../ui/spacer.tsx'

type Props = {
  children: React.ReactNode
  userRole?: RoleType
}

export const MainLayout = ({ children, userRole }: Props) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  })
  const [isDrawerOpen, setDrawerOpen] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (isDrawerOpen === undefined) {
      setDrawerOpen(!isMobile)
    }
  }, [isMobile, isDrawerOpen])

  useEffect(() => {
    if (isMobile && isDrawerOpen) {
      setDrawerOpen(false)
    }
  }, [isMobile])

  const drawerItems =
    userRole === 'user'
      ? userDrawerItems
      : userRole === 'professional'
        ? professionalDrawerItems
        : userRole === 'admin'
          ? adminDrawerItems
          : guestDrawerItems

  return (
    <div className="flex">
      {/* Duplicated Drawer to avoid the problem of showing them wrong on first render because isOpen default state is different for both */}
      <DrawerNavigator
        isMobile={true}
        items={drawerItems}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      <DrawerNavigator
        isMobile={false}
        items={drawerItems}
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      <div className="w-full overflow-hidden">
        <AppBar onHamburgerMenu={() => setDrawerOpen(!isDrawerOpen)} />
        <div className="flex-1">
          <div
            style={{
              minHeight: `calc(100vh - ${isMobile ? 235 : 60 + 120}px)`,
            }}
          >
            {children}
          </div>
          <Spacer size="sm" />
          <Footer />
        </div>
      </div>
    </div>
  )
}
